import React, { useEffect, useState, useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from '@sanity/block-content-to-react';

import IconPaws from '../../icons/IconPaws';
import IconLinkedIn from '../../icons/IconLinkedIn';
import IconPhone from '../../icons/IconPhone';
import IconEmail from '../../icons/IconEmail';

export default function SingleMember({
  name,
  designation,
  whatYouDo,
  pic,
  petPic,
  petAlt,
  socials,
  _rawBio,
  serializers,
}) {
  const accordionContent = useRef(null);
  const [openAccordion, setOpenAccordion] = useState(false);

  useEffect(() => {
    const content = accordionContent.current;
    if (openAccordion) {
      content.style.maxHeight = `${content.scrollHeight}px`;
    } else {
      content.style.maxHeight = null;
    }
  });

  return (
    <>
      <div className={openAccordion ? 'member active' : 'member'}>
        <div className="member-info accordion">
          <div className="img">
            <GatsbyImage image={pic.asset.gatsbyImageData} alt={name} />
            <div className="pet-pic">
              {petPic ? (
                <GatsbyImage image={petPic} alt={petAlt} className="pet-pic" />
              ) : (
                <p>
                  <IconPaws />
                  Pet pending..
                </p>
              )}
            </div>
          </div>
          <div className="info">
            <h3>{name}</h3>
            {designation && <p className="mono designation">{designation}</p>}
            {whatYouDo && <p className="mono occupation">{whatYouDo}</p>}
            <div className="buttons">
              {socials && (
                <div className="socials">
                  {socials.linkedIn && (
                    <a
                      href={socials.linkedIn}
                      target="_blank"
                      className="icon"
                      rel="noreferrer"
                    >
                      <IconLinkedIn />
                    </a>
                  )}
                  {socials.phone && (
                    <a
                      href={`tel:${socials.phone}`}
                      target="_blank"
                      className="icon"
                    >
                      <IconPhone />
                    </a>
                  )}
                  {socials.email && (
                    <a
                      href={`mailto:${socials.email}`}
                      target="_blank"
                      className="icon"
                    >
                      <IconEmail />
                    </a>
                  )}
                </div>
              )}
              <div
                className="details-btn"
                aria-hidden
                role="button"
                tabIndex={-1}
                onClick={() => setOpenAccordion(!openAccordion)}
              >
                {openAccordion ? '- fewer details' : '+ more details'}
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-content" ref={accordionContent}>
          {_rawBio && (
            <PortableText blocks={_rawBio} serializers={serializers} />
          )}
        </div>
      </div>
    </>
  );
}
